import { fetchVideos } from 'api/airtable/airtable';
import Spinner from 'components/Common/Spinner/Spinner';
import PlaylistView from 'components/PlaylistView/PlaylistView';
import { YoutubeVideoInfo } from 'model/YoutubeVideoInfo';
import moment from 'moment';
import { ReactElement, useEffect, useState } from 'react';

interface IProps {
  selectedCalendarDate: Date;
}

export default function PlayerContainer({ selectedCalendarDate }: IProps): ReactElement {
  const [videos, setVideos] = useState<YoutubeVideoInfo[] | undefined>();
  const [videosForMonth, setVideosForMonth] = useState<YoutubeVideoInfo[] | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingError, setLoadingError] = useState('');

  useEffect(() => {
    async function getVideos(): Promise<void> {
      try {
        setIsLoading(true);
        const data = await fetchVideos();
        setVideos(data);
      } catch (err) {
        setLoadingError('Error retrieving videos from server');
        console.error(`Error occurred: `, err);
      } finally {
        setIsLoading(false);
      }
    }
    getVideos();
  }, []);

  useEffect(() => {
    // Filter videos by date.
    // Note, as of the present day, there is no possibility of passing in the date to
    // query by, when we query data from Airtable API.
    if (videos) {
      const firstDayOfTheMonth = moment.utc(selectedCalendarDate).startOf('month');
      const lastDayOfTheMonth = moment.utc(selectedCalendarDate).endOf('month');

      const filteredVideos = videos.filter((video) => {
        const createdDate = moment.utc(video.createdDate);
        return createdDate >= firstDayOfTheMonth && createdDate <= lastDayOfTheMonth;
      });

      setVideosForMonth(filteredVideos);
    }
  }, [selectedCalendarDate, videos]);

  if (isLoading) {
    return (
      <div className="centered-spinner">
        <Spinner />
      </div>
    );
  }

  if (loadingError) {
    return <p className="error-text">{loadingError}</p>;
  }

  if (videosForMonth && videosForMonth.length === 0) {
    return (
      <>
        <p>No videos for the selected month</p>
      </>
    );
  }

  return (
    <>{videosForMonth ? <PlaylistView selectedCalendarDate={selectedCalendarDate} videos={videosForMonth} /> : null}</>
  );
}
