import moment from 'moment';
import { ReactElement } from 'react';

import styles from './MonthYearDisplay.module.scss';

interface IProps {
  selectedCalendarDate: Date;
}

export default function MonthYearDisplay({ selectedCalendarDate }: IProps): ReactElement {
  const monthYear = moment.utc(selectedCalendarDate).format('MMMM YYYY');
  return <div className={styles.month}>{monthYear}</div>;
}
