import axios from 'axios';
import { ResponseVideos } from 'model/ResponseVideos';
import { YoutubeVideoInfo } from 'model/YoutubeVideoInfo';

export async function fetchVideos(): Promise<YoutubeVideoInfo[]> {
  const uRL = `${process.env.REACT_APP_AIRTABLE_URL}/${process.env.REACT_APP_AIRTABLE_BASE}/videos`;

  const headers = { Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_API_KEY}` };
  const fetchedData = await axios.get<ResponseVideos>(uRL, { headers });
  if (fetchedData) {
    const videos: YoutubeVideoInfo[] = fetchedData.data.records.map((record) => ({
      artist: record.fields.artistName,
      songName: record.fields.songTitle,
      comment: record.fields.songDescription,
      url: record.fields.youtubeLink,
      employeeName: record.fields.submitterName,
      createdDate: record.createdTime,
    }));

    return videos;
  }
  return [];
}
