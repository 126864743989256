import { ReactElement } from 'react';
import styles from './Spinner.module.scss';

export default function Spinner(): ReactElement {
  return (
    <div className={styles['spinner-body']}>
      <div className={styles['loading-spinner']} />
    </div>
  );
}
