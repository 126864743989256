import React, { useState } from 'react';
import Header from 'components/Header/Header';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import PlayerContainer from 'components/PlayerContainer/PlayerContainer';
import styles from './App.module.scss';

function App(): React.ReactElement {
  const [selectedCalendarDate, setSelectedCalendarDate] = useState(new Date());

  return (
    <div className={styles.app}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Header selectedCalendarDate={selectedCalendarDate} setSelectedCalendarDate={setSelectedCalendarDate} />
        <PlayerContainer selectedCalendarDate={selectedCalendarDate} />
      </MuiPickersUtilsProvider>
    </div>
  );
}

export default App;
