import { ReactElement, useState } from 'react';
import { Tooltip } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';

import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import styles from './Header.module.scss';
import logo from '../../assets/fm_acronym_logo.png';
import calendarLight from '../../assets/calendar_icon_light.png';
import calendarDark from '../../assets/calendar_icon_dark.png';

interface IProps {
  selectedCalendarDate: Date;
  setSelectedCalendarDate: (date: Date) => void;
}

export default function Header({ selectedCalendarDate, setSelectedCalendarDate }: IProps): ReactElement {
  const [monthPickerOpen, setMonthPickerOpen] = useState(false);

  const onChangeMonth = (date: MaterialUiPickersDate): void => {
    if (date) {
      setSelectedCalendarDate(date);
    }
  };

  const onChangeMonthPickerOpen = (): void => {
    setMonthPickerOpen((prev) => !prev);
  };

  return (
    <div className={styles.header}>
      <div className={styles['header-content']}>
        <img src={logo} alt="FM logo" className={styles.logo} />
        <div className={styles['app-title']}>playlist</div>
      </div>
      <div className={styles['change-view-icons']}>
        <Tooltip title="Change Month">
          <span
            className={styles['calendar-icon']}
            onClick={onChangeMonthPickerOpen}
            onKeyDown={onChangeMonthPickerOpen}
            role="button"
            tabIndex={0}
          >
            <img src={calendarLight} alt="Calendar" className={styles['calendar-light']} />
            <img src={calendarDark} alt="Calendar" className={styles['calendar-dark']} />
          </span>
        </Tooltip>
        <DatePicker
          disableFuture
          autoOk
          open={monthPickerOpen}
          variant="dialog"
          openTo="year"
          value={selectedCalendarDate}
          views={['year', 'month']}
          onChange={onChangeMonth}
          onClose={() => setMonthPickerOpen(false)}
          className={styles['month-picker']}
          minDate={new Date('2021-05-01')}
        />
      </div>
    </div>
  );
}
