/* eslint-disable @typescript-eslint/no-explicit-any */
import { YoutubeVideoInfo } from 'model/YoutubeVideoInfo';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import cx from 'classnames';

import { getVideoIdsFromURLs } from 'utils/getVideoIds';
import YouTubePlayer from 'youtube-player';
import { YouTubePlayer as IYouTubePlayer } from 'youtube-player/dist/types';
import MonthYearDisplay from 'components/MonthYearDisplay/MonthYearDisplay';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { PlayerState } from '../../enum/PlayerState';

import styles from './PlaylistView.module.scss';

interface IProps {
  selectedCalendarDate: Date;
  videos: YoutubeVideoInfo[];
}

// This ID identifies the div into which the youtube iframe is loaded
const videoPlayerDivID = 'video-player';

export default function PlaylistView({ selectedCalendarDate, videos }: IProps): ReactElement {
  const [playingVideoInfo, setPlayingVideoInfo] = useState<YoutubeVideoInfo | undefined>();
  const [previousPlayer, setPreviousPlayer] = useState<IYouTubePlayer | undefined>();
  const { width: screenWidth } = useWindowDimensions();

  const updateSongInfo = useCallback(
    (url: string): void => {
      const videoInfo = videos.find((video) => video.url === url);
      setPlayingVideoInfo(videoInfo);
    },
    [videos]
  );

  const onPlayerStateChange = useCallback(
    (event: any): void => {
      const player = event.target;
      switch (event.data) {
        case PlayerState.PLAYING:
          if (player.getVideoUrl) {
            updateSongInfo(player.getVideoUrl());
          }
          break;

        default:
          break;
      }
    },
    [updateSongInfo]
  );

  useEffect(() => {
    if (previousPlayer) {
      // destroy previous instance so that a new playlist will take effect
      previousPlayer.destroy();
    }
    const videoIDs = getVideoIdsFromURLs(videos.map((vid) => vid.url));
    const playerWidth = screenWidth > 640 ? 640 : screenWidth;
    const player = YouTubePlayer(videoPlayerDivID, {
      width: playerWidth,
    });
    setPreviousPlayer(player);
    // register state changes callback
    player.on('stateChange', onPlayerStateChange);
    const playlistItems: ReadonlyArray<string> = videoIDs;
    player.cuePlaylist(playlistItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onPlayerStateChange, videos]);

  return (
    <>
      <MonthYearDisplay selectedCalendarDate={selectedCalendarDate} />
      <div className={cx(styles['employee-name'], { [styles.hidden]: !playingVideoInfo?.employeeName })}>
        {playingVideoInfo?.employeeName || 'placeholder'}
      </div>
      <div id={videoPlayerDivID} />
      <div className={styles['info-container']}>
        <div className={styles['song-name']}>{playingVideoInfo?.songName}</div>
        <div className={styles.artist}>{playingVideoInfo?.artist}</div>
        <div className={styles.comment}>{playingVideoInfo?.comment}</div>
      </div>
    </>
  );
}
