const youtubeIdLength = 11;

export const getVideoIdFromURL = (url: string): string => {
  const trimmedURL = url.trim();
  const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = trimmedURL.match(regExp);
  if (match) {
    const youtubeId = match[2];
    if (youtubeId.length === youtubeIdLength) {
      return youtubeId;
    }
  }

  console.error('Error in extracting youtube video ID for URL: ', trimmedURL);
  return '';
};

export const getVideoIdsFromURLs = (urls: string[]): string[] => {
  const filteredVideoIDs: string[] = [];
  urls.forEach((url) => {
    const videoID = getVideoIdFromURL(url);
    if (videoID) {
      filteredVideoIDs.push(videoID);
    }
  });
  return filteredVideoIDs;
};
